/**
 * Site header
 */
.site-header {
    min-height: 110px;
    background-color: $osc-color;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    color: $inverse-text-color;
    font-size: 32px;
    font-weight: 300;
    line-height: 105px;
    margin-bottom: 0;
    margin-left: -10px;
    float: left;

    &,
    &:visited,
    &:hover {
        text-decoration: none;
        color: $inverse-text-color;
    }

    @include media-query($on-palm) {
        font-size: 24px;
    }
}

.site-nav {
    float: right;
    line-height: 110px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $inverse-text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            color: $text-color;
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}

.slogan {
    color: $grey-color;
    text-align: center;
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(65% - (#{$spacing-unit} / 2));
    width:         calc(65% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Pagination
 */

.pagination {
  text-align: center;
}

.previous,
.next {
    margin: 0 1em;
    font-weight: bold;
}

.disabled {
  color: $grey-color;
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}

.post-comment-count {
    text-align: right;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 1.5em;

        @include media-query($on-laptop) {
            font-size: 1.2em;
        }
    }

    h3 {
        font-size: 1.3em;

        @include media-query($on-laptop) {
            font-size: 1.1em;
        }
    }

    h4 {
        font-size: 1.1em;

        @include media-query($on-laptop) {
            font-size: 1em;
        }
    }
}

.center-image {
    margin: 0 auto;
    display: block;
}

.category-label {
  font-style: italic;
}


/**
 * Slack Invitation
 */
.slack-invitation {
    height: 250px;
    width: 300px;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}
.slack-help-link {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}
